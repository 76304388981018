import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default class Hydrocarbon extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="Hydrocarbon" lang="en" />

        <section className="wow animate__fadeIn">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-5 col-md-6 text-center md-margin-30px-bottom wow animate__fadeInLeft">
                <StaticImage
                  src="../../images/Hydrocarbon.JPG"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className="border-radius-6 w-100 shadow mt-4"
                />
              </div>
              <div className="col-12 col-lg-7 col-md-6 text-center text-md-start padding-eight-lr lg-padding-six-lr md-padding-15px-lr wow animate__fadeInRight" data-wow-delay="0.2s">

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">What is Hydrocarbon?</span>

                <p>It is a type of organic material which is only structured by carbon and hydrogen. Hydrocarbons split into two categories of saturated and unsaturated which they use in several industries based on their features. The simplest type of hydrocarbon is Alkane in which each carbon is connected to two hydrogens. In general, hydrocarbons can be found in oil and gas. These materials are used in numerous industries such as paint, lubricants, and plastics and so on.</p>

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">Light Hydrocarbon</span>

                <p>A large number of production methods of light hydrocarbon from heavy petroleum compounds are based on chemical procedures using catalysts and upper temperature cause to increase the final prices of manufacturing of lighter products. Hence, development of alternative methods in these processes to reduce the energy consumption, can pave the way for the development of the production process of lighter petroleum compounds than heavy petroleum compounds with higher economic value.</p>

                <p>In this regard, cold plasma can effectively play a role in breaking down heavy petroleum compounds and converting them into valuable light hydrocarbons. Cold plasma can be a correct option for using in chemical process because of its innate nature (an ionized medium with high energy level). Providing an ionized environment with energetic ions that can be a starter of many chemical reactions, including cracking, which can be used as another choice in common methods of refining process. These are so useful in many of the refineries in the whole world. Light hydrocarbon is often used in producing aromatic compounds and gasoline with high octane number. Moreover, they can be used as solvent or feed in the chemical and petrochemical industries in order to produce several types of petrochemical products such as solvents. The difference in the number of carbon leads standing out of light hydrocarbon from other hydrocarbons. These materials are transparent liquids with gentle fragrant and chemically stable chemical aspect and don’t cause any kinds of corrosion.</p>

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">Heavy Hydrocarbon</span>

                <p>They are other types of hydrocarbons which they have low economical value due to certain features such as high viscosity and density. One of the reasons of less economical value of heavy hydrocarbons is the difficulty of manufacturing and their use in energy production process.Heavy hydrocarbon is one the middle distillate fuels which is used in domestic and industrial burners as well as internal combustion engines.  In addition, it is considered as one of the mid-distillation fuels of oil refineries, which has a combination of resin and alkyl.</p>

                <a className="btn btn-primary" href="/files/Heavy-Hydrocarbonn-H70.pdf">Download</a>

              </div>
            </div>
          </div>
        </section>

        <Footer />

      </Layout>

    )

  }

}